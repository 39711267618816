<template>
  <div class="test">
    <el-header class="header">
      <el-row class="edit-header">
        <el-col :span="5" class="lf-bt">
          <el-button type="warning" @click="back" style="width: 100px"
            >Go Back</el-button
          >
        </el-col>
      </el-row>
    </el-header>

    <el-container class="container">
      <el-main class="main">
        <div
          class="ques"
          style="display: flex; justify-content: center; margin: 2%"
        >
          <el-input
            v-model="userEmail"
            placeholder="Please enter user email"
            style="width: 40%; margin-right: 5%"
          ></el-input>
          <el-button @click="searchUser">Search</el-button>
        </div>
        <el-form ref="form" label-width="100px">
          <el-row>
            <el-col :span="1">
              <p></p>
            </el-col>
            <el-col :span="4">
              <p>Industry</p>
            </el-col>
            <el-col :span="1">
              <p></p>
            </el-col>
            <el-col :span="5">
              <p>Company Type</p>
            </el-col>
            <el-col :span="2">
              <p></p>
            </el-col>
            <el-col :span="4">
              <p>License Balance</p>
            </el-col>
            <el-col :span="4">
              <p>Free Balance</p>
            </el-col>
          </el-row>
          <el-form-item v-for="(item, index) in values" :key="index">
            <el-row>
              <el-col :span="5">
                <el-input :disabled="true" v-model="item.industry"></el-input>
              </el-col>
              <el-col :span="1">
                <p style="line-height: 13px; margin-left: 10px"></p>
              </el-col>
              <el-col :span="4">
                <el-input :disabled="true" v-model="item.company"></el-input>
              </el-col>
              <el-col :span="2">
                <p></p>
              </el-col>
              <el-col :span="4">
                <el-input type="number" v-model="item.balance"> </el-input>
                <p
                  v-if="item.balance != item.original_balance"
                  style="
                    margin-left: -30px;
                    margin-top: -7px;
                    margin-bottom: -10px;
                    color: rgba(251, 89, 89, 0.845);
                  "
                >
                  click 'Save' to submit change
                </p>
              </el-col>
              <el-col :span="1">
                <p style="line-height: 13px; margin-left: 10px"></p>
              </el-col>
              <el-col :span="4">
                <el-input type="number" v-model="item.free_balance"> </el-input>
                <p
                  v-if="item.free_balance != item.original_free_balance"
                  style="
                    margin-left: -30px;
                    margin-top: -7px;
                    margin-bottom: -10px;
                    color: rgba(251, 89, 89, 0.845);
                  "
                >
                  click 'Save' to submit change
                </p>
              </el-col>
              <el-col :span="1">
                <el-button
                  :disabled="
                    item.balance == item.original_balance &&
                      item.free_balance == item.original_free_balance
                  "
                  type="primary"
                  plain
                  class="addOptionButton"
                  @click="save(item)"
                  >Save</el-button
                >
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import getDataApi from "@/utils/getDataApi";
import saveDataApi from "@/utils/saveDataApi";

export default {
  name: "test",
  mixins: [getDataApi, saveDataApi],
  data() {
    return {
      values: [],
      userId: "",
      userEmail: "",
      description: [],
      all_qn: [
        {
          id: "0",
          industry_id: "",
          company_id: "",
          name: "No Questionnaire",
        },
      ],
      qid: this.$route.query.qid,
    };
  },
  components: {},
  methods: {
    back() {
      this.$router.push("/index");
    },
    save(item) {
      this.$confirm(
        "This will change the license of this user, do you want the change to take effect?",
        "提示",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        if (item.original_balance != "" && item.original_free_balance != "") {
          let post_data = {
            fk_user_id: this.userId,
            fk_industry_id: item.industry_id,
            fk_company_type_id: item.company_id,
            balance_increase: item.balance - item.original_balance,
            free_balance_increase:
              item.free_balance - item.original_free_balance,
          };
          this.$axios({
            method: "PUT",
            url: `https://api.oxvalue.ai/api/license/v0.1/user_license_admin/${item.license_id}/`,
            headers: {
              Authorization: window.localStorage.getItem("ova_admin_token"),
            },
            data: post_data,
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: `License updated successfully!`,
              });
            }
            item.original_balance = item.balance;
            item.original_free_balance = item.free_balance;
          });
        } else {
          let post_data = {
            fk_user_id: this.userId,
            fk_industry_id: item.industry_id,
            fk_company_type_id: item.company_id,
            balance: item.balance,
            free_balance: item.free_balance,
          };
          this.$axios({
            method: "POST",
            url: `https://api.oxvalue.ai/api/license/v0.1/user_license_admin/`,
            headers: {
              Authorization: window.localStorage.getItem("ova_admin_token"),
            },
            data: post_data,
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: `License updated successfully!`,
              });
            }
            item.original_balance = item.balance;
            item.original_free_balance = item.free_balance;
          });
        }
      });
    },
    find_qn(item) {
      let temp_list = [
        {
          id: "0",
          industry_id: "",
          company_id: "",
          name: "No Questionnaire",
          label: "No Questionnaire",
        },
      ];
      for (let i in this.all_qn) {
        if (
          item.industry_id == this.all_qn[i].industry_id &&
          item.company_id == this.all_qn[i].company_id
        )
          temp_list.push(this.all_qn[i]);
      }
      return temp_list;
    },
    searchUser() {
      this.$axios({
        method: "get",
        url: `https://api.oxvalue.ai/api/user/v0.1/user_admin/?email=${this.userEmail}`,
        headers: {
          Authorization: window.localStorage.getItem("ova_admin_token"),
        },
      }).then((res) => {
        if (res.data.data.user_list.length <= 0) {
          this.$message.error("Wrong user email！");
          return;
        }
        this.$message.success("Search Successfully");
        this.userId = res.data.data.user_list[0].id;
        this.$axios({
          method: "get",
          url: `https://api.oxvalue.ai/api/license/v0.1/user_license_admin/?user=${this.userId}`,
          headers: {
            Authorization: window.localStorage.getItem("ova_admin_token"),
          },
        }).then((res1) => {
          let license_list = res1.data.data.license_list;
          // clear last search result
          for (let j = 0; j < this.values.length; j++) {
            this.values[j].balance = "";
            this.values[j].original_balance = "";
            this.values[j].original_free_balance = "";
          }
          // fill in this search result
          for (let i = 0; i < license_list.length; i++) {
            for (let j = 0; j < this.values.length; j++) {
              if (
                license_list[i].fk_industry_id == this.values[j].industry_id &&
                license_list[i].fk_company_type_id == this.values[j].company_id
              ) {
                this.values[j].balance = license_list[i].balance;
                this.values[j].free_balance = license_list[i].free_balance;
                this.values[j].license_id = license_list[i].id;
                this.values[j].original_balance = license_list[i].balance;
                this.values[j].original_free_balance =
                  license_list[i].free_balance;
              }
            }
          }
        });
      });
    },
  },
  computed: {},
  created() {
    var industry_list = [];
    var company_list = [];
    this.$axios({
      method: "get",
      url: `https://api.oxvalue.ai/api/question/v0.1/industry_admin/`,
      headers: {
        Authorization: window.localStorage.getItem("ova_admin_token"),
      },
    }).then((res) => {
      if (res.data["code"] == 403) {
        this.$store.dispatch("clear");
        window.localStorage.removeItem("ova_admin_token");
        this.$router.push("/login");
        return;
      }
      industry_list = res.data.data.industry_list;
      this.$axios({
        method: "get",
        url: `https://api.oxvalue.ai/api/question/v0.1/company_type_admin/`,
        headers: {
          Authorization: window.localStorage.getItem("ova_admin_token"),
        },
      })
        .then((res2) => {
          company_list = res2.data.data.company_type_list;
        })
        .then(() => {
          for (let i = 0; i < industry_list.length; i++) {
            for (let j = 0; j < company_list.length; j++) {
              this.values.push({
                industry_id: industry_list[i].id,
                industry_name: industry_list[i].name,
                industry: industry_list[i].id + ". " + industry_list[i].name,
                company_id: company_list[j].id,
                company_name: company_list[j].name,
                company: company_list[j].id + ". " + company_list[j].name,
                using_qn_id: "",
                using_qn_name: "",
                using_qn: "",
                changed: false,
                is_available: false,
                balance: "",
                free_balance: "",
                original_balance: "",
                original_free_balance: "",
                license_id: "",
              });
            }
          }
        });
    });
  },
  mounted() {},
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style>
.test .linkDialog {
  text-align: left;
}
.test .container {
  padding: 0;
  height: auto;
  min-height: 610px;
}

.test header {
  padding: 0 100px;
}

.test .el-container {
  padding: 0 100px;
}

.test .side {
  border-top: solid 1px #e3e3e3;
  border-right: solid 1px #e3e3e3;
  background: #ffffff;
}

.test .main {
  border-top: solid 1px #e3e3e3;
  background: #ffffff;
}

.test .edit {
  margin-top: 0;
  overflow: auto;
  height: 550px;
}

.test .outline {
  overflow: auto;
  height: 550px;
}

.test .ques-type {
  padding: 15px 0;
  font-size: 16px;
  border-bottom: dashed #e3e3e3 1px;
}

.test .edit-title {
  color: black;
  padding: 15px 0;
  font-size: 16px;
  border-bottom: solid #e3e3e3 2px;
}

.test .edit-ques {
  padding: 15px 0;
  font-size: 16px;
  border-bottom: dashed #e3e3e3 1px;
}

.test .type-icon {
  color: #1687fd;
  display: inline-block;
}

.test .type-icon:hover {
  color: #409eff;
  cursor: pointer;
}

.test .el-tabs__nav-scroll {
  text-align: center;
  height: 60px;
  margin: 0 60px;
}

.test .el-tabs__item {
  font-weight: bold;
  padding: 0 20px;
  height: 60px;
  box-sizing: border-box;
  line-height: 60px;
  display: inline-block;
  list-style: none;
  font-size: 16px;
  color: black;
  position: relative;
}

.test .el-tabs__header {
  margin: 0;
}

.test .el-tree-node__content {
  padding-left: 10px !important;
  height: 40px;
}

/* .test .main {
  max-height: 610px;
} */

.test .ques .title {
  font-size: 28px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 26px;
}

.test .ques .description {
  text-align: left;
  font-size: 16px;
  padding-bottom: 30px;
  color: black;
  line-height: 30px;
}

.test .ques-block {
  padding-bottom: 15px;
  border-top: solid #e3e3e3 1px;
}

.test .ques-block:hover {
  background: #f5f5f5;
  transition: 0.3s;
}

.test .ques-block .must {
  font-weight: normal;
  color: crimson;
}

.test .block-title {
  text-align: left;
  /*border: solid 1px black;*/
  font-size: 16px;
  padding: 20px 10px 10px;
  font-weight: bold;
}

.test .block-description {
  text-align: left;
  /*border: solid 1px black;*/
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 10px;
  padding-left: 10px;
  color: #969696;
}

.test .block-refer {
  text-align: right;
  /*border: solid 1px black;*/
  font-size: 14px;
  padding-top: 8px;
  padding-left: 10px;
  color: #51c215;
}

.test .block-point {
  text-align: right;
  /*border: solid 1px black;*/
  font-size: 14px;
  padding-top: 8px;
  padding-left: 10px;
  color: #e59824;
}

.test .block-choice {
  text-align: left;
  /*border: solid 1px black;*/
  font-size: 16px;
  padding: 5px 10px 10px;
}

.test .el-button-group > .el-button:first-child {
  border-radius: 0 0 0 8px;
}

.test .el-button-group > .el-button:last-child {
  border-radius: 0 0 8px 0;
}

.test .block-button .el-button {
  background: #b9b9b9;
  border: transparent;
  padding: 12px 16px;
  font-size: 16px;
}

.test .block-button .el-button:hover {
  background: #989898;
  border: transparent;
}

.test .bt {
  color: white;
  font-size: 14px;
  font-weight: bold;
}

.test .block-choice .el-textarea__inner {
  max-height: 100px;
}

.test .dialog {
  text-align: left;
  font-size: large;
}
.test .addOptionButton {
  display: inline-block;
  margin-left: 100px;
}
.test .deleteOptionButton {
  margin-left: 20px;
}

.test .logic-description {
  margin-top: -15px;
  margin-bottom: 30px;
  text-align: left;
  padding-left: 6px;
  font-size: 14px;
  color: #aaaaaa;
}

.test .important {
  color: crimson;
}

.test .logic-title {
  font-size: 16px;
  font-weight: bold;
  padding-left: 12px;
  padding-bottom: 30px;
}

.test .logic-bt {
  text-align: center;
}

.test .addOptionButton {
  display: inline-block;
  margin-left: 100px;
  margin-bottom: 22px;
}

.test .deleteOptionButton {
  margin-left: 20px;
}

.test .settingDialog {
  text-align: center;
}

.test .settingDialog .el-dialog__body {
  padding-left: 30px;
}

.test .logic-form .el-input--suffix .el-input__inner {
  width: 620px;
}

.test .logic-info {
  color: #aaaaaa;
  padding-top: 2px;
  padding-left: 12px;
  padding-bottom: 25px;
}

.test .block-relation {
  text-align: right;
  /*border: solid 1px black;*/
  font-size: 14px;
  padding-top: 8px;
  padding-left: 10px;
  color: #1687fd;
}
</style>
